import React from 'react';
import { useTranslation } from 'react-i18next';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import TextAnimation from '../components/TextAnimation';
import Mailer from '../components/Mailer';

export default function ContactUs() {
  const { t } = useTranslation();
  const bannerImage = '../images/contact.jpg';
  const map = '../images/map.jpg';

  return (
    <>
      <div className="imgHeaderService d-flex justify-content-center align-items-center h-100">
        <img
          className="d-flex w-100 bg-image"
          src={bannerImage}
          alt={bannerImage}
        ></img>

        <div>
          <div className="content">
            <h6>
              <TextAnimation />
            </h6>
            <strong>{t('Contact_us')}</strong>
          </div>
        </div>
      </div>

      <div className="container  d-flex my-4">
        <div className="contact_form ">
          <h4>
            <strong>{t('Contact_us')}</strong>
          </h4>

          <div className="bodycontact d-flex">
            {/* <div className="transparent w-100"> */}
              <div className="container">
                <div className="row">
                  <div className="col-sm-12 col-md-12">
                    <img
                      className="d-flex w-100 p-4"
                      src={map}
                      alt={map}
                    ></img>
                  </div>
                </div>
              </div>
            {/* </div> */}
          </div>
          <div className="bodycontact d-flex">
            <div className="transparent w-100">
              <div className="container">
                <div className="row">
                  <div className="col-sm-12 col-md-12">
                    {/* <h6 className="footer-title d-flex">{t('Contact_us')}</h6> */}
                    <div className="d-flex my-2 p-2">
                      <i className="fa fa-map-marker colorInfo  fa-lg"></i>
                      العراق- بغداد -مجمع بوابة العراق السکني بنایة ٣٢ ط٦ شقة ٣٤
                    </div>
                    <div className="d-flex p-2 my-2">
                      <i className="fa fa-envelope colorInfo fa-sm"></i>
                      info@aelrafidain.com
                    </div>
                    <div className="d-flex p-2 my-2 ">
                      <i className="fa fa-phone colorInfo fa-lg"></i>
                      00964 770 087 7777
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <Row className="m-4">
            <Col></Col>
            <Col className="formcontact p-4" sm={11}>
              <Mailer></Mailer>
            </Col>
            <Col></Col>
            {/* <Col
              sm={6}
              className="d-flex justify-content-center align-center align-items-center agency "
            >
              <h2 className="text-color1">{t('contact_statement')}</h2>
            </Col> */}

            {/* <div className="image-group"></div> */}
          </Row>
        </div>
      </div>
    </>
  );
}
